import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";

const ServiceCard = ({ title, text, image, imgAlt }) => {
	return (
		<Col xl={4} md={6} className="px-md-4 mb-5 text-start">
			<div style={{ maxHeight: "221px" }}>
				<GatsbyImage
					style={{ maxHeight: "221px" }}
					image={image}
					alt={imgAlt}
				/>
			</div>
			<h3 className="normal-font fs-3 fw-bold mt-3">{title}</h3>
			<hr
				className="bg-primary"
				style={{ height: "5px", width: "150px", opacity: "1" }}
			/>
			<p>{text}</p>
		</Col>
	);
};

export default ServiceCard;
