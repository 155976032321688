import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../../components/layout";
import ServiceHeroSmall from "../../components/service-hero-small";
import CtaBanner from "../../components/cta-banner";
import Reviews from "../../components/reviews";
import ServiceCard from "../../components/service-card";
import Navigation from "../../components/navigation";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

import { GatsbyImage } from "gatsby-plugin-image";
const TranslationPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Translation-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			translationImg: wpMediaItem(title: { eq: "Translation-Services" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			proofImg: wpMediaItem(title: { eq: "Proof-Reading-Services" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			certImg: wpMediaItem(title: { eq: "Certification-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			transcreationImg: wpMediaItem(title: { eq: "Transcreation-Services" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const certImg = data.certImg.localFile.childImageSharp.gatsbyImageData;
	const heroImg = data.heroImg.localFile.childImageSharp.gatsbyImageData;
	const translationImg =
		data.translationImg.localFile.childImageSharp.gatsbyImageData;
	const proofImg = data.proofImg.localFile.childImageSharp.gatsbyImageData;
	const transcreationImg =
		data.transcreationImg.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Example",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Translation",
				item: {
					url: `${siteUrl}/services/translation`,
					id: `${siteUrl}/services/translation`,
				},
			},
		],
	};

	return (
		<Layout>
			<Navigation />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Translation Services in London"
				description="I offer Italian into English translation, French into English translation and Spanish into English translation services."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services/translation`,
					title: "Translation Services in London",
					description:
						"I offer Italian into English translation, French into English translation and Spanish into English translation services.",
					images: [
						{
							url: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/translation-services-scaled-2-683x1024-1.jpg`,
							width: 683,
							height: 1024,
							alt: "Translation services in London",
						},
					],
				}}
			/>
			<ServiceHeroSmall
				imgWidth="18rem"
				image={heroImg}
				imgAlt={data.heroImg.altText}
				title="Translation"
				heading="Looking for a competent, accurate translation service for your business?"
				text={
					<>
						<p>
							I offer Italian into English translation, French into English
							translation and Spanish into English translation services for
							individual and corporate clients and agencies in London and
							worldwide.
						</p>
						<p>
							I have worked as a full-time translator since being awarded my
							Master’s degree in Translation in 2007. I have translated a
							variety of texts over the years and now specialise in marketing
							translations, financial translations, pharmaceutical translations
							and technical translations. I use the latest translation software
							provided by SDL Trados. I am a certified member of the Institute
							of Translation and Interpreting as well as being a member of the
							ITI’s local Weald translators’ network.
						</p>
					</>
				}
			/>
			<Container className="mb-4">
				<Row>
					<ServiceCard
						image={translationImg}
						imgAlt={data.translationImg.altText}
						title="Translation"
						text={
							<>
								<p>
									I provide professional, targeted, industry-specific
									translations from Italian into English, French into English
									and Spanish into English, crafted by a human translator with
									more than 12 years of experience.
								</p>
								<p>
									As a certified, qualified translator I can provide the right
									text for you to suit your business needs.
								</p>
								<p>
									Whether you need creative, marketing/website content to be
									translated to reach new and existing customers or an annual
									report/technical manual for users and maintenance operators, I
									will produce a fluent translation that reads as if it was
									originally written in English.
								</p>
							</>
						}
					/>
					<ServiceCard
						image={proofImg}
						imgAlt={data.proofImg.altText}
						title="Proofreading"
						text={
							<>
								<p>
									I also have extensive experience of proofreading and editing
									other translators’ work. This means I can provide a
									professional proofreading service.
								</p>
								<p>
									So if you have a high level of English and would like your
									text proofread by a native English speaker who also knows your
									mother tongue and the cultural, historic and political nuances
									of your country, contact me today for a quote.
								</p>
								<p>
									Or, if you simply want a translation you have received from
									another provider to be proofread by a professional who knows
									the correct terminology, tone and style to use as well as the
									source language, I can provide this service for you.
								</p>
							</>
						}
					/>
					<ServiceCard
						image={transcreationImg}
						imgAlt={data.transcreationImg.altText}
						title="Transcreation"
						text="Stay ahead of the game among your competitors with posts and articles written for you that generate engagement and cross-platform sharing. Free up more time to focus on other aspects of your business by outsourcing your social media to a professional copywriter."
					/>
				</Row>
			</Container>
			<section>
				<Reviews />
			</section>
			<section className="py-6 pt-lg-4 py-xl-7 text-center">
				<GatsbyImage
					image={certImg}
					style={{ maxWidth: "200px" }}
					alt={data.certImg.altText}
				/>
			</section>
			<CtaBanner
				headline={
					<>
						I'd love to hear more about your
						<br className="d-none d-md-block" /> needs, and how I can help.
					</>
				}
				bgColor="dark-background"
				btnTxt="Contact"
				btnLink="/contact"
			/>
		</Layout>
	);
};

export default TranslationPage;
